@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  font-family: 'Poppins', sans-serif !important;
  background-color: #F6F6F6 !important;
}

.cs-message-input__content-editor-wrapper,.cs-message-input__content-editor{
  background-color: white !important;
}

.cs-chat-container, .cs-message-input{
  border-radius: 10px !important;
}

.cs-message--outgoing .cs-message__content{
  background-color: white !important;
  box-shadow: 4px 1px 2px rgba(94, 99, 116, 0.05) !important;
border-radius: 5px !important;
margin-top: 2% !important;

}


.cs-message--incoming .cs-message__content{
  background-color: white !important;
  box-shadow: 0px 1px 2px rgba(94, 99, 116, 0.05) !important;
  border-radius: 5px !important;
  margin-top: 2% !important;
}

.cs-message-list, .cs-typing-indicator,.cs-message-list{
  background-color: #F6F6F6 !important;
}

.cs-message-input{
  border-radius: 10px !important;
  border-top: none !important;
}

.cs-conversation-header, .cs-conversation-header__user-name{
  background-color: white !important;
  border-bottom: none !important;
}

.rti--container{
  height: 2.2375em !important;
  border-radius: 5px !important;
  background-color: transparent !important;
}

.rti--input{
  background-color: transparent !important;
}

iframe{
  overflow: hidden !important;
}

.Mui-selected{
  color: '#3770FF' !important;
}

 .iuwJTF{
  background-color: transparent !important;
}

.fOCJfr {
  background: transparent !important;
  color: #3770FF !important;
  border-radius: 50px !important;
  height: 40px;
  padding: 0px 18px;
  border: 2px solid #3770FF !important;
  margin: 0px !important;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  font-family: 'Poppins' !important;
}

.fOCJfr:hover{
  background-color: #3770FF !important;
  color: white !important;
}

.hiDjLR{
  position:inherit !important;
}

.record-button__RecWrapper-sc-1n5amwk-1{
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 1%;
}

.render-actions__ActionsWrapper-dp6lnv-0{
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
}

.UqtrK{
  height: 50px !important;
  width:50px !important;
}

.cBsNfk{
  height: 34px !important;
  width:34px !important;
}

.cJDMfn{
  margin-bottom: 0 !important;
}

.hjXGLh{
  height: 50px !important;
  width:50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.hiDjLR{
  bottom: 0 !important;
}

.ftNHGd{
  height: 25px !important;
  width:25px !important;
  margin:0 !important;
}

@media screen and (max-width:990px) {
  .chat-mycont { flex-wrap: wrap; 
  };
  .chatcontinsidediv{
  }
}
.gooey {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    background: transparent;
    /* filter: contrast(20); */
  }
  .gooey .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    left: 15px;
    /* filter: blur(4px); */
    background: #000;
    border-radius: 50%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation: dot 2.8s infinite;
            animation: dot 2.8s infinite;
  }
  .gooey .dots {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    margin-top: 12px;
    margin-left: 31px;
    -webkit-animation: dots 2.8s infinite;
            animation: dots 2.8s infinite;
  }
  .gooey .dots span {
    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    /* filter: blur(4px); */
    background: #000;
    border-radius: 50%;
  }
  @-webkit-keyframes dot {
    50% {
      -webkit-transform: translateX(96px);
              transform: translateX(96px);
    }
  }
  @keyframes dot {
    50% {
      -webkit-transform: translateX(96px);
              transform: translateX(96px);
    }
  }
  @-webkit-keyframes dots {
    50% {
      -webkit-transform: translateX(-31px);
              transform: translateX(-31px);
    }
  }
  @keyframes dots {
    50% {
      -webkit-transform: translateX(-31px);
              transform: translateX(-31px);
    }
  }
  
* {
    padding: 0;
    margin: 0
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@-webkit-keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@-webkit-keyframes scale {

    0%,
    100% {
        -webkit-transform: none;
                transform: none
    }

    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes scale {

    0%,
    100% {
        -webkit-transform: none;
                transform: none
    }

    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1)
    }
}

@-webkit-keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142
    }
}
* {
    padding: 0;
    margin: 0
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@-webkit-keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@-webkit-keyframes scale {

    0%,
    100% {
        -webkit-transform: none;
                transform: none
    }

    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes scale {

    0%,
    100% {
        -webkit-transform: none;
                transform: none
    }

    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1)
    }
}

@-webkit-keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142
    }
}
