@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
  font-family: 'Poppins', sans-serif !important;
  background-color: #F6F6F6 !important;
}

.cs-message-input__content-editor-wrapper,.cs-message-input__content-editor{
  background-color: white !important;
}

.cs-chat-container, .cs-message-input{
  border-radius: 10px !important;
}

.cs-message--outgoing .cs-message__content{
  background-color: white !important;
  box-shadow: 4px 1px 2px rgba(94, 99, 116, 0.05) !important;
border-radius: 5px !important;
margin-top: 2% !important;

}


.cs-message--incoming .cs-message__content{
  background-color: white !important;
  box-shadow: 0px 1px 2px rgba(94, 99, 116, 0.05) !important;
  border-radius: 5px !important;
  margin-top: 2% !important;
}

.cs-message-list, .cs-typing-indicator,.cs-message-list{
  background-color: #F6F6F6 !important;
}

.cs-message-input{
  border-radius: 10px !important;
  border-top: none !important;
}

.cs-conversation-header, .cs-conversation-header__user-name{
  background-color: white !important;
  border-bottom: none !important;
}

.rti--container{
  height: 2.2375em !important;
  border-radius: 5px !important;
  background-color: transparent !important;
}

.rti--input{
  background-color: transparent !important;
}

iframe{
  overflow: hidden !important;
}

.Mui-selected{
  color: '#3770FF' !important;
}

 .iuwJTF{
  background-color: transparent !important;
}

.fOCJfr {
  background: transparent !important;
  color: #3770FF !important;
  border-radius: 50px !important;
  height: 40px;
  padding: 0px 18px;
  border: 2px solid #3770FF !important;
  margin: 0px !important;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  font-family: 'Poppins' !important;
}

.fOCJfr:hover{
  background-color: #3770FF !important;
  color: white !important;
}

.hiDjLR{
  position:inherit !important;
}

.record-button__RecWrapper-sc-1n5amwk-1{
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 1%;
}

.render-actions__ActionsWrapper-dp6lnv-0{
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
}

.UqtrK{
  height: 50px !important;
  width:50px !important;
}

.cBsNfk{
  height: 34px !important;
  width:34px !important;
}

.cJDMfn{
  margin-bottom: 0 !important;
}

.hjXGLh{
  height: 50px !important;
  width:50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.hiDjLR{
  bottom: 0 !important;
}

.ftNHGd{
  height: 25px !important;
  width:25px !important;
  margin:0 !important;
}

@media screen and (max-width:990px) {
  .chat-mycont { flex-wrap: wrap; 
  };
  .chatcontinsidediv{
  }
}